import React from 'react';

function Header() {
    return (
        <h3 style={{padding: 40, textAlign: 'center'}}>
            Welcome to this capstone project of MSCSE Spring 2020 class in <br />
            Whitewater University of California! 
        </h3>
    );
}

export default Header;
